<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        อนุมัติโอนสินค้าเข้า : TO3-2
      </h3>
      <div class="card-toolbar"></div>
    </div>

    <div class="card-body">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัส Transfer</th>
              <th>ชื่อ Transfer</th>
              <th>วันที่สร้างเอกสาร</th>
              <th>มูลค่า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>
                <a style="font-weight: bold" @click="goToItemDetail(item)"
                  ><u>
                    {{ item.transfer_no ? item.transfer_no : "" }}
                  </u>
                </a>
              </td>
              <td>
                {{ item.transferee_code ? item.transferee_code : "" }}
              </td>
              <td>
                {{ item.transferee_name ? item.transferee_name : "" }}
              </td>
              <td>{{ formatDate(item.createdAt.substring(0, 10)) }}</td>

              <td>
                {{ item.final_price ? item.final_price : "" }}
              </td>

              <td
                @click="
                  role === 'STAFF'
                    ? ''
                    : item.status === '0'
                    ? handleApprove(item)
                    : ''
                "
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: #3ca76c; font-weight: 500'
                    : item.status === '0'
                    ? 'cursor: pointer; color: #0098DA; font-weight: 500'
                    : 'cursor: ; color: red; font-weight: 500'
                "
              >
                {{
                  item.status === "1"
                    ? "อนุมัติโอนสินค้า"
                    : item.status === "0"
                    ? "รออนุมัติใบโอนสินค้า"
                    : "ไม่อนุมัติ"
                }}
              </td>
              <td>
                <div class="row">
                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success me-sm-2 p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i
                        :class="
                          item.status === '0'
                            ? 'bi bi-pencil-square'
                            : 'bi bi-eye-fill'
                        "
                      ></i>
                    </button>
                  </div>

                  <div class="col-sm-4">
                    <button
                      :disabled="item.status === '1'"
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>

                  <div @mousedown="hoverExportBtn()" class="col-sm-4">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import transferApi from "@/api/transfer/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import moment from "moment";
import Loader from "../../components/loaderIndex.vue";
import Pagination from "../../components/pagination.vue";

import { Decode, Encode } from "@/services";

import pdfFile from "../../components/files/transferInFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    pdfFile,
    Loader,
    Pagination,
  },
  setup() {
    document.title = "BELUCA | อนุมัติโอนสินค้าเข้า";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    companyItems: [],

    showing1: null,

    dialogConfirmByPass: false,
    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    dateSearch: "",
    allData: [],

    role: "",
  }),

  created() {
    this.role = Decode.decode(localStorage.getItem("role")).replace(
      /^"(.*)"$/,
      "$1"
    );
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await transferApi.transferProduct.getAll({
          transfer_type: "1",
          companyId: companyId,
          companyBranchId: companyBranchId,
          // status: "0",
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        console.log(responseData.data);
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push("/transfer-product/TP3-1/new");
    },
    goToEdit(id) {
      this.$router.push({
        path: "/transfer-product/TP3-1/edit",
        query: {
          id: id,
        },
      });
    },
    goToItemDetail(item) {
      this.$router.push({
        path: "/transfer-product/TP3-1/edit",
        query: {
          id: item.id,
          status: item.status,
        },
      });
    },
    gotoImportExcel() {
      this.$router.push("/warehouse/WH4/importexcel");
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.transfer_no} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await transferApi.transferProduct.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },

    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },

    async statusApprove(isApprove, method) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
        // is_cancel: method === "cancel" ? "1" : "0",
        is_cancel: "0",
      };

      updateResponse = await transferApi.transferProduct.update(
        id,
        this.itemApprove
      );
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          this.updateProduct();
          Swal.fire({
            icon: `success`,
            title: "อนุมัติสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    // update item
    async updateProduct() {
      const updateResponse = await whApi.product.multiUpdate(
        this.itemApprove.transferProductItems,
        "transA"
      );

      if (updateResponse.response_status === "ERROR") {
        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      }
      // let countArr = 0;
      // for (const element of this.itemApprove.transferProductItems) {
      //   countArr += 1;
      //   await this.getOneProduct(element.product.id, element.item_amt);
      // }
    },
    async getOneProduct(id, item_amt) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(item_amt, getOneResponse.data);
      }
    },
    async updateProductAmountRecieve(item_amt, item) {
      let updateResponse = [];
      let sumQty = parseFloat(item.product_bring_qty) + parseFloat(item_amt);

      const body = {
        ...item,
        product_bring_qty: sumQty,
        isUpdateQty: true,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },
    // Export file

    async search() {
      const newDate = this.dateSearch
        ? moment(this.dateSearch.$d).format("YYYY-MM-DD")
        : "";

      this.loading = true;

      const responseSearch = await saleApi.salesOrder.search(
        {
          search: this.searchInput,
          date: newDate,
        },
        "1"
      );

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
